<template>
  <div>
      <div>
          <img :src="$auth.user.picture">
          <div class="text-h2">{{ $auth.user.name }}</div>
          <p>{{ $auth.user.email }}</p>
      </div>

      <div>
          <pre>{{ JSON.stringify($auth.user, null, 2) }}</pre>
      </div>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>